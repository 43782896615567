<template>
  <div>
    <div class="pc">
      <div v-if="product.enable_option">
        <div class="lp-divider-gray1 padding-top-24"></div>
        <!-- 필수옵션 -->
        <div v-for="(selector, idx) in optionArr1" :key="`option1-${idx}`">
          <div v-if="!selector.disabled">
            <div class="padding-top-32 h8">{{ selector.name }}</div>
            <div :class="{'plan-select-wrapper': selector.name.indexOf('플랜') > -1}">
              <!-- 옵션 정보 -->
              <template v-for="(option, o_idx) in selector.options">
                <div v-if="!option.disabled" :key="`op1-${o_idx}`"
                     class="option-st unselect"
                     :class="{'disabled': selector.disabled, 'selected': option.selected}"
                     @click="selectOption1(option, selector)">
                  <div class="body2-medium sub" v-html="option.name"></div>
                  <div v-if="option.price>0">
                    <span class="body4-bold main">{{ option.name.indexOf('3개월')>-1 ? option.price *3 : option.price | currencyNum }}</span>
                    <span class="body4 sub">원</span>
                    <span class="body4 sub3" v-if="option.is_subscription">/{{ option.name.indexOf('3개월')> -1 ? '3개월' : '월' }}</span>
                  </div>
                  <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 추가옵션 -->
        <div v-if="optionArr2 && optionArr2.length>0">
          <div class="lp-divider-gray3 padding-top-32"></div>
          <div v-for="(selector, idx) in optionArr2" :key="`option2-${idx}`">
          <div class="padding-top-32 h8">{{ selector.name }}</div>
          <!-- 옵션 정보 -->
          <div v-for="(option, o_idx) in selector.options" :key="`op2-${o_idx}`">
            <div class="option-st unselect"
                 :class="{'selected': option.selected}"
                 @click="selectOption2(option)">
              <div class="flex-between">
                <div class="body2-medium sub" v-html="option.name"></div>
                <div class="margin-top-4" v-if="option.price>0">
                  <span class="body4-bold main">+{{ option.price | currencyNum }}</span>
                  <span class="body4 sub">원</span>
                  <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                </div>
              </div>
              <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
            </div>
          </div>
        </div>
        </div>

        <div class="lp-divider-gray3 padding-top-32"></div>
        <div class="flex-between padding-top-24">
          <div class="subtitle5">
            <span class="sub2">금액<span class="primary">(정기 결제)</span></span>
          </div>
          <div>
            <span class="body2-bold main">
              {{ order_product.required_option_price + order_product.add_option_price| currencyNum }}
            </span>
            <span class="body4 sub">원<span class="sub3">/월</span></span>
          </div>
        </div>

        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ order_product.total_price | currencyNum }}<span class="body2 main">원</span><span class="body2 sub3">/월</span></div>
        </div>
        <!-- 플랫폼 팩 고지 -->
        <div class="margin-top-24" v-if="product.category2_name === '플랫폼 팩'">
          <div v-for="(item,idx) in info" :key="'info-'+idx" class="flex" style="margin-bottom:8px">
            <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="margin:4px 6px 0 0;width:20px;height:20px">
            <div class="body4 sub3">{{ item }}</div>
          </div>
        </div>
      </div>
      <!-- 자체 상품 -->
      <div v-else>
        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
      </div>

      <div class="padding-top-40">
        <template v-if="product.category2_name === '추가서비스' || isPrime">
          <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="clickInquiry">1:1 문의하기</button>
        </template>
        <template v-else-if="used">
          <button class="button is-dark body2-bold" style="width:100%;height:52px" >
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" style="vertical-align: sub;margin-right: 4px;">
              <path d="M20 6L9 17L4 12" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>이용 중</button>
        </template>
        <template v-else>
          <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="saveData">
            {{ userService.service_order.trial ? '무료 체험하기' : '구매하기' }}</button>
          <div class="body2 sub2 margin-top-16 text-center">다음 결제일까지 무료 / 이후 월 15만원 추가 (부가세 별도)</div>
        </template>
      </div>
    </div>

    <div class="mobile">
      <div v-if="product.enable_option">
        <!-- 필수옵션 -->
        <div v-for="(selector, idx) in optionArr1" :key="`option1-${idx}`">
          <div v-if="!selector.disabled">
            <div class="padding-top-24 body4-bold padding-bottom-4">{{ selector.name }}</div>
            <!-- 옵션 정보 -->
            <div v-for="(option, o_idx) in selector.options" :key="`op1-${o_idx}`">
              <div class="option-st unselect"
                   :class="{'disabled': selector.disabled, 'selected': option.selected}"
                   @click="selectOption1(option, selector)"
                   v-if="!option.disabled">
                <div class="body4-medium sub" v-html="option.name"></div>
                <div class="margin-top-4" v-if="option.price>0 && idx>0">
                  <span class="body4-bold main">{{ option.price | currencyNum }}</span>
                  <span class="body4 sub">원</span>
                  <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                </div>
                <div class="body6 sub3 margin-top-4">{{ option.desc }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 추가옵션 -->
        <div v-if="optionArr2 && optionArr2.length>0">
          <div class="lp-divider-gray3 margin-top-24"></div>
          <div v-for="(selector, idx) in optionArr2" :key="`option2-${idx}`">
            <div class="padding-top-24 body4-bold padding-bottom-4">{{ selector.name }}</div>
            <!-- 옵션 정보 -->
            <div v-for="(option, o_idx) in selector.options" :key="`op2-${o_idx}`">
              <div class="option-st unselect"
                   :class="{'selected': option.selected}"
                   @click="selectOption2(option)">
                <div class="flex-between">
                  <div class="body4-medium sub" v-html="option.name"></div>
                  <!--<div v-if="option.price>0">
                    <span class="body4-bold main">+{{ option.price | currencyNum }}</span>
                    <span class="body4 sub">원</span>
                    <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                  </div>-->
                </div>
                <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="lp-divider-gray3 padding-top-32"></div>
        <div class="flex-between padding-top-16">
          <div class="body5-medium">
            <span class="sub2">금액<span class="primary">(정기 결제)</span></span>
          </div>
          <div>
            <span class="body3-bold main">
              {{ order_product.required_option_price | currencyNum }}
            </span>
            <span class="body5 sub">원<span class="sub3">/월</span></span>
          </div>
        </div>
        <div class="flex-between padding-top-4" v-if="order_product.add_option_price">
          <div class="body5-medium">
            <span class="sub2">추가옵션</span>
          </div>
          <div>
            <span class="body3-bold main">
              {{ order_product.add_option_price | currencyNum }}
            </span>
            <span class="body5 sub">원<span class="sub3">/월</span></span>
          </div>
        </div>
        <div class="lp-divider-gray2 padding-top-16"></div>

        <div class="flex-between padding-top-12">
          <div class="subtitle6 main">총 결제금액</div>
          <div class="body0-bold main">{{ order_product.total_price | currencyNum }}원</div>
        </div>

        <div class="padding-top-16">
          <button class="button is-gray2 body5 flex-center" style="width:100%;height:40px">
            <img src="/static/icon/fi_alert-circle.svg"
                 style="width:16px;height:16px" alt="" class="unselect svg-sub3">
            <span class="margin-left-4 sub3">결제는 데스크톱에서 진행해주세요.</span>
            </button>
        </div>
      </div>
      <div v-else-if="product.category2===42 || product.category2===45">
        <div class="flex-between padding-top-24" v-if="product.price.price_type===0">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
        <div class="padding-top-24" v-else>
          <div class="h7">관리자에게 문의</div>
        </div>
        <div class="padding-top-16">
          <button class="button is-primary body4-medium" style="width:100%" @click="clickInquiry">1:1 문의 하기</button>
        </div>
      </div>
      <!-- 자체 상품 -->
      <div v-else>
        <div class="flex-between">
          <div class="subtitle6">총 결제금액</div>
          <div class="body0-bold">{{ discountedPrice | currency }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import OrderMixin from "../../mixins/OrderMixin";

  export default {
    name: "MarketOptionSelector",

    mixins: [
      OrderMixin
    ],
    props: {
      product: {
        type: Object
      }
    },
    created() {
      this.init();
    },
    data() {
      return {
        // 비교할 상품 옵션
        selectorStocks: [],
        // 필수옵션
        optionArr1: [],
        // 추가옵션
        optionArr2: [],
        order_product: undefined,
        // 필수값 선택값 체크용
        requiredOptionsValue: [],
        info: [
          '팩 추가 시, 화면 빌더에서 해당하는 페이지의 페이지 보드로 추가됩니다.',
          '구매한 팩의 주문화면으로 나오게 할 상품 카테고리를 관리자에서 지정해 주어야 합니다.',
          '다음 결제일 이전에 별도의 해지신청이 없는 경우, 다음 결제일에 해당 금액만큼 추가되어 자동으로 청구됩니다.',
          '케어서비스 선택 시 충전금에서 차감되며, 케어서비스를 이용하고 있지 않은 고객님께서 신청하신 경우는 별도로 연락 드려 안내 드립니다.'
        ]
      }
    },
    computed: {
      // Prime 여부
      isPrime() {
        if(this.product.category2_name === '플러그인') {
          let grade = this.product.params.filter(i=>{ return i.name === '플러그인 등급' });
          if(grade.length===0) {
            return false;
          }
          grade = grade[0];
          let val = grade.choice_items.filter(i=>{ return i.id === grade.value_select[0]});
          if(val.length === 0) {
            return false;
          } else if(val[0].name === 'Prime') {
            return true;
          }

        }
        return false;
      },
      // 이용중 여부
      used() {
        let key = this.product.params.filter(i => { return i.name === 'key'})[0].value_text;
        let service_key = '';
        if(this.product.category2_name === '플랫폼 팩') {
          service_key = 'packages';
        } else if(this.product.category2_name === '플러그인') {
          service_key = 'plugins';
        }

        return service_key === '' ? false : this.userService[service_key].indexOf(key)>-1;
      },
      // 할인 여부
      isDiscount() {
        if (this.product.price.is_discount && this.product.price.discount_start !== null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < this.product.price.discount_start || currentDate > this.product.price.discount_end);
        } else if (this.product.price.discount_start !== null && this.product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= this.product.price.discount_start;
        } else if (this.product.price.discount_start === null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= this.product.price.discount_end;
        } else {
          return true;
        }
      },
      // 상품 가격
      discountedPrice() {
        let total = 0;
        if (this.isDiscount) {
          if (this.product.price.discount_rate !== 0 && this.product.price.discount_type === 0) {
            total = this.product.price.price * (1 - (this.product.price.discount_rate / 100))
          } else if (this.product.price.discount_price !== 0 && this.product.price.discount_type === 1) {
            total = this.product.price.price - this.product.price.discount_price;
          }
        } else total = this.product.price.price;
        return total;
      },
    },
    methods: {
      init() {
        this.order_product = {
          product_id: this.product.id,
          quantity: 1,
          discount_price: 0,
          product_price: 0,
          total_price: 0,
          product: this.product,
          order_product_option: [],
          required_option_price: 0,
          add_option_price: 0
        }
        this.initOptions();
        this.getData();
      },
      initOptions() {
        // 옵션 초기화
        let selectors = this.product.selectors;
        this.optionArr1 = selectors.filter(item => {
          item.disabled = true;
          return item.option_type===0;
        })
        if(this.optionArr1.length>0) {
          this.optionArr1[0].disabled = false;
        }
        this.optionArr2 = selectors.filter(item => {
          return item.option_type===1;
        })
      },
      getData() {
        let url = `public/product/${this.product.id}/selector_stocks`
        // let url = `http://localhost:8001/public/product/${this.product.id}/selector_stocks`
        this.$axios.post(url).then(res=>{
          this.selectorStocks = res.data.stocks;
        })
      },
      selectedOptionName(selectorStocks, option_id) {
        if(!selectorStocks) return;
        let stock = this.findItem(selectorStocks, 'id', option_id);
        if (stock) return stock.price > 0 ? `${stock.name}: +${stock.price}원` : `${stock.name}: ${stock.price}원`;
      },
      // 추가옵션 선택
      selectOption2(option) {
        if(!option.selected) {
          option.selected = true;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option = this.findItem(this.order_product.order_product_option, 'option_id', stock.id);
              if(!option) {
                this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
              }
            }
          })
        } else {
          option.selected = false;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option_index = this.order_product.order_product_option.findIndex(opo=> { return opo.option_id === stock.id });
              if(option_index>-1) {
                this.order_product.order_product_option.splice(option_index, 1);
              }
            }
          })
        }
        this.calcTotalPrice();
      },
      // 필수옵션 선택
      selectOption1(option, selector) {
        let option_id = option.id;
        if(!option_id) return;
        // 옵션 선택시 false로 초기화 후 옵션 선택
        selector.options.forEach(op=>{
          op.selected = false;
        });
        option.selected = true;

        this.selectorStocks.forEach(stock=>{
          if(stock.options.indexOf(option.id) > -1) {
            this.initOrderProductOption();
            this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
          }
        })

        this.calcTotalPrice();
      },
      calcTotalPrice() {
        let total = 0;
        let requiredOptionPrice = 0;
        let addOptionPrice = 0;
        // 상품 옵션일 경우
        if(this.product.enable_option) {
          // required_option_price = 필수옵션
          // add_option_price = 추가옵션
          // total_price = 필수옵션 + 추가옵션
          this.order_product.order_product_option.forEach(item=> {
            let op = this.findItem(this.selectorStocks, 'id', item.option_id);
            if(op!==undefined) {
              if(item.option_type===0) {
                requiredOptionPrice += op.price * parseInt(item.quantity)
              } else if(item.option_type===1) {
                addOptionPrice += op.price * parseInt(item.quantity)
              }
              total += op.price * parseInt(item.quantity)
            }
          })
        }
        // 자체 상품일 경우
        else {
          total = this.discountedPrice * this.order_product.quantity;
        }
        this.order_product.product_price = total;
        this.order_product.required_option_price = requiredOptionPrice;
        this.order_product.add_option_price = addOptionPrice;
        this.order_product.total_price = total;
      },
      initOrderProductOption() {
        let opoIndex = this.order_product.order_product_option.findIndex(opo=> opo.option_type===0)
        if(opoIndex>-1) this.order_product.order_product_option.remove(opoIndex);
      },
      saveData() {
        // if(this.user.user_id===0)
        let item = this.findItem(this.order_product.order_product_option, 'option_type', 0);
        if(!item) {
          this.toast('필수 옵션을 선택하세요.');
        } else {
          let params = {
            type: 'market',
            order_product: this.cloneItem(this.order_product),
            subscribe_price: this.order_product.required_option_price + this.order_product.add_option_price
          };
          this.$store.commit('setBasket', params);
          this.routeGa('', this.product.title, '앱마켓 체험하기');
          this.routerPush('account', true);
        }
      },
      clickInquiry() {
        this.routeGa('', this.product.title, '1:1문의하기');
        let id = this.product.category2_name === '플러그인' ? 7 : 10;
        this.routerPush(`inquiry_reg?type_id=${id}`, true);
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .option-st
    border 1px solid $gray1
    border-radius 8px
  .pc
    .option-st
      margin-top 16px
      padding 16px 24px
    .option-st:hover
      border 1px solid $sub3
  .mobile
    .option-st
      padding 12px 16px
      margin-top 8px

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .plan-select-wrapper
    display grid
    grid-gap 12px
    grid-template-columns repeat(2, 1fr)
</style>
